const sponsorList = [
  {
    id: 0,
    name: "bet365",
    logo: "/images/bet365.png",
    website: "https://www.bet354.com",
    intro_video: null,
    end_video: null,
    color: {
      primary: "#DE1A38",
      secondary: "#105992",
      stroke: "#5A4179",
      background: "#96305A",
    },
    created_at: 1596380561288,
    updated_at: 1596380561288,
  },
  {
    id: 1,
    name: "betfred",
    logo: "/images/betfred.png",
    website: "https://www.betfred.com",
    intro_video: null,
    end_video: null,
    color: {
      primary: "#DE1A38",
      secondary: "#105992",
      stroke: "#5A4179",
      background: "#96305A",
    },
    created_at: 1596380561288,
    updated_at: 1596380561288,
  },
  {
    id: 2,
    name: "bet365",
    logo: "/images/bet365.png",
    website: "https://www.bet354.com",
    intro_video: null,
    end_video: null,
    color: {
      primary: "#DE1A38",
      secondary: "#105992",
      stroke: "#5A4179",
      background: "#96305A",
    },
    created_at: 1596380561288,
    updated_at: 1596380561288,
  },
  {
    id: 3,
    name: "betfred",
    logo: "/images/betfred.png",
    website: "https://www.betfred.com",
    intro_video: null,
    end_video: null,
    color: {
      primary: "#DE1A38",
      secondary: "#105992",
      stroke: "#5A4179",
      background: "#96305A",
    },
    created_at: 1596380561288,
    updated_at: 1596380561288,
  },
  {
    id: 4,
    name: "bet365",
    logo: "/images/bet365.png",
    website: "https://www.bet354.com",
    intro_video: null,
    end_video: null,
    color: {
      primary: "#DE1A38",
      secondary: "#105992",
      stroke: "#5A4179",
      background: "#96305A",
    },
    created_at: 1596380561288,
    updated_at: 1596380561288,
  },
  {
    id: 5,
    name: "betfred",
    logo: "/images/betfred.png",
    website: "https://www.betfred.com",
    intro_video: null,
    end_video: null,
    color: {
      primary: "#DE1A38",
      secondary: "#105992",
      stroke: "#5A4179",
      background: "#96305A",
    },
    created_at: 1596380561288,
    updated_at: 1596380561288,
  },
  {
    id: 6,
    name: "bet365",
    logo: "/images/bet365.png",
    website: "https://www.bet354.com",
    intro_video: null,
    end_video: null,
    color: {
      primary: "#DE1A38",
      secondary: "#105992",
      stroke: "#5A4179",
      background: "#96305A",
    },
    created_at: 1596380561288,
    updated_at: 1596380561288,
  },
  {
    id: 7,
    name: "betfred",
    logo: "/images/betfred.png",
    website: "https://www.betfred.com",
    intro_video: null,
    end_video: null,
    color: {
      primary: "#DE1A38",
      secondary: "#105992",
      stroke: "#5A4179",
      background: "#96305A",
    },
    created_at: 1596380561288,
    updated_at: 1596380561288,
  },
];

const oddsMakerList = [
  {
    id: 0,
    name: "Bet365 API",
    odds: { home: [160, 150], away: [-101, -100] },
  },
  {
    id: 1,
    name: "Betfair API",
    odds: { home: [140, 150], away: [-99, -100] },
  },
  {
    id: 2,
    name: "Willian Hill API",
    odds: { home: [140, 150], away: [-101, -100] },
  },
];

const matchList = [
  {
    id: 0,
    isActive: true,
    title: "Linara v Crolla",
    matchId: "MR7QQR4",
    date: 1596380561288,
    sponsorList: [
      {
        id: 0,
        sponsorId: 0,
        area: [
          {
            id: 0,
            center: "Denver, CO",
            radius: 40,
          },
          {
            id: 1,
            center: "Trenton, NJ",
            radius: 17,
          },
        ],
      },
      {
        id: 1,
        sponsorId: 1,
        area: [
          {
            id: 0,
            center: "Denver, CO",
            radius: 40,
          },
          {
            id: 1,
            center: "Trenton, NJ",
            radius: 17,
          },
        ],
      },
    ],
  },
  {
    id: 1,
    isActive: true,
    title: "Ortiz v Vargas",
    matchId: "MR7QQR5",
    date: 1596380561288,
  },
  {
    id: 2,
    isActive: true,
    title: "Canelo vs Jacobs",
    matchId: "MR7QQR6",
    date: 1596380561288,
  },
  {
    id: 3,
    isActive: false,
    title: "Canelo vs Jacobs",
    matchId: "MR7QQR7",
    date: 1796380561288,
  },
  {
    id: 4,
    isActive: false,
    title: "Canelo vs Jacobs",
    matchId: "MR7QQR8",
    date: 1796380561288,
  },
  {
    id: 5,
    isActive: false,
    title: "Canelo vs Jacobs",
    matchId: "MR7QQR9",
    date: 1796380561288,
  },
  {
    id: 6,
    isActive: false,
    title: "Canelo vs Jacobs",
    matchId: "MR7QQR9",
    date: 1796380561288,
  },
  {
    id: 7,
    isActive: false,
    title: "Canelo vs Jacobs",
    matchId: "MR7QQR9",
    date: 1796380561288,
  },
  {
    id: 8,
    isActive: false,
    title: "Canelo vs Jacobs",
    matchId: "MR7QQR9",
    date: 1796380561288,
  },
  {
    id: 9,
    isActive: false,
    title: "Canelo vs Jacobs",
    matchId: "MR7QQR9",
    date: 1796380561288,
  },
];

const permissionList = [
  {
    id: 0,
    permission: "admin",
  },
  {
    id: 1,
    permission: "sponsor",
  },
  {
    id: 2,
    permission: "user",
  },
];

const userList = [
  {
    id: 0,
    username: "John Wayne",
    firstname: "John",
    lastname: "Wayne",
    email: "johnwayne@gmail.com",
    number: "830-849-3295",
    permission: 0,
    sponsorId: 0,
    created_at: 1596380561288,
    updated_at: 1596380561288,
  },
  {
    id: 1,
    username: "John Wayne",
    firstname: "John",
    lastname: "Wayne",
    email: "johnwayne@gmail.com",
    number: "830-849-3295",
    permission: 0,
    sponsorId: 0,
    created_at: 1596380561288,
    updated_at: 1596380561288,
  },
  {
    id: 2,
    username: "John Wayne",
    firstname: "John",
    lastname: "Wayne",
    email: "johnwayne@gmail.com",
    number: "830-849-3295",
    permission: 0,
    sponsorId: 0,
    created_at: 1596380561288,
    updated_at: 1596380561288,
  },
  {
    id: 3,
    username: "John Wayne",
    firstname: "John",
    lastname: "Wayne",
    email: "johnwayne@gmail.com",
    number: "830-849-3295",
    permission: 0,
    sponsorId: 0,
    created_at: 1596380561288,
    updated_at: 1596380561288,
  },
  {
    id: 4,
    username: "John Wayne",
    firstname: "John",
    lastname: "Wayne",
    email: "johnwayne@gmail.com",
    number: "830-849-3295",
    permission: 0,
    sponsorId: 0,
    created_at: 1596380561288,
    updated_at: 1596380561288,
  },
  {
    id: 5,
    username: "John Wayne",
    firstname: "John",
    lastname: "Wayne",
    email: "johnwayne@gmail.com",
    number: "830-849-3295",
    permission: 0,
    sponsorId: 0,
    created_at: 1596380561288,
    updated_at: 1596380561288,
  },
  {
    id: 6,
    username: "John Wayne",
    firstname: "John",
    lastname: "Wayne",
    email: "johnwayne@gmail.com",
    number: "830-849-3295",
    permission: 0,
    sponsorId: 0,
    created_at: 1596380561288,
    updated_at: 1596380561288,
  },
  {
    id: 7,
    username: "John Wayne",
    firstname: "John",
    lastname: "Wayne",
    email: "johnwayne@gmail.com",
    number: "830-849-3295",
    permission: 0,
    sponsorId: 0,
    created_at: 1596380561288,
    updated_at: 1596380561288,
  },
  {
    id: 8,
    username: "John Wayne",
    firstname: "John",
    lastname: "Wayne",
    email: "johnwayne@gmail.com",
    number: "830-849-3295",
    permission: 0,
    sponsorId: 0,
    created_at: 1596380561288,
    updated_at: 1596380561288,
  },
  {
    id: 9,
    username: "John Wayne",
    firstname: "John",
    lastname: "Wayne",
    email: "johnwayne@gmail.com",
    number: "830-849-3295",
    permission: 0,
    sponsorId: 0,
    created_at: 1596380561288,
    updated_at: 1596380561288,
  },
  {
    id: 10,
    username: "John Wayne",
    firstname: "John",
    lastname: "Wayne",
    email: "johnwayne@gmail.com",
    number: "830-849-3295",
    permission: 0,
    sponsorId: 0,
    created_at: 1596380561288,
    updated_at: 1596380561288,
  },
  {
    id: 11,
    username: "John Wayne",
    firstname: "John",
    lastname: "Wayne",
    email: "johnwayne@gmail.com",
    number: "830-849-3295",
    permission: 0,
    sponsorId: 0,
    created_at: 1596380561288,
    updated_at: 1596380561288,
  },
];

const playerList = [
  {
    id: 0,
    photo: "/images/player1.png",
    name: "Jorge Linares",
    nationality: "USA",
    height: 173,
    reach: 67,
    wrist: 65,
    neck: 15,
    chest: 37,
    bicep: 13,
    forearm: 10,
    calf: 13,
    stance: "Right",
    color: "#105992",
    history: [
      {
        id: 0,
        date: 1596380561288,
        opponent: "Gabriel Bracero",
        win: 12,
        loss: 4,
        draw: 0,
        last6: [1, 0, 1, 0, 1, 0],
        result: "W TKO",
      },
      {
        id: 1,
        date: 1596380561288,
        opponent: "John Taylor",
        win: 7,
        loss: 5,
        draw: 2,
        last6: [1, 1, 1, 0, 1, 1],
        result: "L UD",
      },
      {
        id: 2,
        date: 1596380561288,
        opponent: "Gabriel Bracero",
        win: 12,
        loss: 4,
        draw: 0,
        last6: [1, 0, 1, 0, 1, 0],
        result: "W TKO",
      },
      {
        id: 3,
        date: 1596380561288,
        opponent: "John Taylor",
        win: 7,
        loss: 5,
        draw: 2,
        last6: [1, 1, 1, 0, 1, 1],
        result: "L UD",
      },
      {
        id: 4,
        date: 1596380561288,
        opponent: "John Taylor",
        win: 7,
        loss: 5,
        draw: 2,
        last6: [1, 1, 1, 0, 1, 1],
        result: "L UD",
      },
    ],
  },
  {
    id: 1,
    photo: "/images/player2.png",
    name: "Anthony Crolla",
    nationality: "USA",
    height: 173,
    reach: 67,
    wrist: 65,
    neck: 15,
    chest: 37,
    bicep: 13,
    forearm: 10,
    calf: 13,
    stance: "Right",
    color: "#581C75",
    history: [
      {
        id: 0,
        date: 1596380561288,
        opponent: "Gabriel Bracero",
        win: 12,
        loss: 4,
        draw: 0,
        last6: [1, 0, 1, 0, 1, 0],
        result: "W TKO",
      },
      {
        id: 1,
        date: 1596380561288,
        opponent: "John Taylor",
        win: 7,
        loss: 5,
        draw: 2,
        last6: [1, 1, 1, 0, 1, 1],
        result: "L UD",
      },
      {
        id: 2,
        date: 1596380561288,
        opponent: "Gabriel Bracero",
        win: 12,
        loss: 4,
        draw: 0,
        last6: [1, 0, 1, 0, 1, 0],
        result: "W TKO",
      },
      {
        id: 3,
        date: 1596380561288,
        opponent: "John Taylor",
        win: 7,
        loss: 5,
        draw: 2,
        last6: [1, 1, 1, 0, 1, 1],
        result: "L UD",
      },
      {
        id: 4,
        date: 1596380561288,
        opponent: "John Taylor",
        win: 7,
        loss: 5,
        draw: 2,
        last6: [1, 1, 1, 0, 1, 1],
        result: "L UD",
      },
    ],
  },
];

export { sponsorList, matchList, permissionList, userList, oddsMakerList, playerList };
