import React from "react";
import { Link } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import "./index.less";

const EditButton = ({ children, style, to, onClick }) => {
  return onClick ? (
    <div className="edit-button-wrapper" style={style} onClick={onClick}>
      <div className="edit-button primary-gradient">
        <EditOutlined />
      </div>
      {children}
    </div>
  ) : (
    <Link className="edit-button-wrapper" style={style} to={to}>
      <div className="edit-button primary-gradient">
        <EditOutlined />
      </div>
      {children}
    </Link>
  );
};

export default EditButton;
