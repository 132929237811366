import React, { useState } from "react";
import { AutoComplete, Input } from "antd";
import "./index.less";

export const placesAvailable = [
  { value: "USA", id: "US" },
  { value: "United Kingdom", id: "GB" },
  { value: "Germany", id: "DE" },
  { value: "France", id: "FR" },
  { value: "China", id: "CHN" },
  { value: "Canada", id: "CA" },
  { value: "Russia", id: "RU" },
];

const PlaceAutoComplete = ({ className, style, selectHandler }) => {
  const [options, setOptions] = useState(placesAvailable);

  const onSelect = (data) => {
    console.log("onSelect", data);
    selectHandler(placesAvailable.find((el) => el.value === data));
  };

  const onSearch = (input) => {
    setOptions(
      placesAvailable.filter(
        (place) => place.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    );
  };

  return (
    <AutoComplete
      className="onyx-place-autocomplete"
      onSelect={onSelect}
      onSearch={onSearch}
      options={options}
    >
      <Input.Search size="large" placeholder="Search Location" />
    </AutoComplete>
  );
};

export default PlaceAutoComplete;
