const uiEnvIdentifiers = {
  local: "localhost",
};

const hostname = window && window.location && window.location.hostname;

const localEndpoints = {
  baseApiUrl: `http://${hostname}:8000`,
  ls_name: "onyxadmin.pid",
};

const prodEndpoints = {
  baseApiUrl: `https://api-sendbox.onyxmatch.com/`,
  ls_name: "onyxadmin.pid",
};

const getEnvEndpoints = () => {
  // if (hostname.includes(uiEnvIdentifiers.local)) {
  //   return localEndpoints;
  // } else {
  return prodEndpoints;
  // }
};

export default getEnvEndpoints;
