import React, { useState, useMemo, useEffect, useRef } from "react";
import { Table, Pagination, Input, Select } from "antd";
import { Layout } from "UI";
import useSWR from "swr";
import { fetcher } from "Utils/request";
import { VList } from "virtual-table-ant-design";
import "./index.less";

const { Search } = Input;
const { Option } = Select;

const pageSizeCurrent = 10;

const PlayersList = () => {
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [selectValue, setSelectValue] = useState("");

  const { data: playersList } = useSWR(
    `/players/playersAll/?page=${page}${
      searchValue ? `&search=` + searchValue : ""
    }&pageSize=${pageSizeCurrent}${selectValue ? `&type=` + selectValue : ""}`,
    fetcher
  );

  const countOfPlayers = playersList?.count;

  const onChange = (item) => {
    setPage(item);
  };

  const colObj = playersList?.rows[0];

  const dataSource = playersList?.rows;

  const columns = useMemo(() => {
    const result = [];
    for (let key in colObj) {
      result.push({ title: `${key}`, dataIndex: `${key}`, key: `${key}`, width: 110 });
    }
    return result;
  }, [colObj]);

  function onChangeSelect(value) {
    console.log(`selected ${value}`);
    setSelectValue(value.toLowerCase());
  }

  function onBlur() {
    console.log("blur");
  }

  function onFocus() {
    console.log("focus");
  }
  const onSearch = (value) => {
    setSearchValue(value.toLowerCase());
  };

  return (
    <Layout menuSelected={4}>
      <div>
        <Search
          placeholder="input search text"
          onSearch={onSearch}
          style={{ width: 200, margin: "20px 0px" }}
        />
        <Select
          showSearch
          style={{ width: 200, margin: "0px 20px" }}
          placeholder="Select a type"
          optionFilterProp="children"
          onChange={onChangeSelect}
          onFocus={onFocus}
          onBlur={onBlur}
          // filterOption={(input, option) =>
          //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          // }
        >
          <Option value="nfl">nfl</Option>
          <Option value="mlb">mlb</Option>
          <Option value="nba">nba</Option>
          <Option value="nhl">nhl</Option>
          <Option value="cfb">cfb</Option>
          <Option value="cbb">cbb</Option>
          <Option value="golf">golf</Option>
          <Option value="nascar">nascar</Option>
          <Option value="soccer">soccer</Option>
          <Option value="wnba">wnba</Option>
          <Option value="cwbb">cwbb</Option>
          <Option value="mma">mma</Option>
        </Select>
        <Table
          loading={!playersList}
          pagination={false}
          dataSource={dataSource}
          columns={columns}
          rowKey="id"
          scroll={{ y: "60vh", x: 1000 }}
          components={VList({
            height: 500,
          })}
          className="admin_players-table"
        />
        <Pagination
          current={page}
          defaultCurrent={page}
          onChange={onChange}
          total={countOfPlayers}
          showSizeChanger={false}
          style={{ display: "flex", justifyContent: "center", margin: "15px" }}
        />
      </div>
    </Layout>
  );
};

export default PlayersList;
