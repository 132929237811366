import React from "react";
import { DatePicker } from "antd";

const OnyxDatePicker = ({ onChange, value, picker }) => {
  return (
    <DatePicker
      className="onyx-date-picker"
      onChange={onChange}
      value={value}
      picker={picker}
      showToday={false}
      allowClear={false}
    />
  );
};

export default OnyxDatePicker;
