import React, { useState } from "react";
import qs from "qs";
import { useHistory } from "react-router";
import { Layout as Wrapper } from "antd";
import { Button, Card, Label, InputText } from "UI";
import withLabel from "Helpers/withLabel";
import { makeRequest } from "Utils/request";
import { persistUser } from "Utils/persist";
import "./index.less";

const Login = ({ onSuccess }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();

  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const path = query.back ? `${query.back}` : "/";

  const onLogin = async () => {
    if (email && password) {
      const data = await makeRequest("post", "/auth/login", {
        email: email,
        password: password,
      });
      if (data.user) {
        await persistUser(data);
        await onSuccess();
        await history.push(path);
      }
    }
  };

  return (
    <Wrapper className="onyx-layout">
      <div className="onyx-login-form">
        <Card className="p-0">
          <div className="flex flex-row">
            <div className="login-bg"></div>
            <div className="w-full px-2 py-3">
              <div className="flex flex-row justify-between items-center mb-2">
                <Label>Login</Label>
                <img src="/images/logo.png" alt="logo" style={{ height: "37px" }} />
              </div>
              {withLabel(
                <InputText
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder=""
                />,
                "Email"
              )}
              {withLabel(
                <InputText
                  value={password}
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder=""
                />,
                "Password"
              )}

              <Button primary onClick={onLogin}>
                Login
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </Wrapper>
  );
};

export default Login;
