import getEnvEndpoints from "../Config/endpoints";

const endpoints = getEnvEndpoints();

export const persistUser = (data) => {
  var oneYearFromNow = new Date();
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

  localStorage.setItem(
    endpoints.ls_name,
    JSON.stringify({
      ...data,
      exp_date: oneYearFromNow,
    })
  );
};

export const getUserData = () => {
  return JSON.parse(localStorage.getItem(endpoints.ls_name));
};

export const getUserId = () => {
  let userId = getUserData()?.user.id;
  return isNaN(userId) ? 0 : userId;
};

export const clearUserData = () => {
  localStorage.removeItem(endpoints.ls_name);
};

export const getToken = () => {
  const userData = getUserData();

  if (userData) {
    return userData.token;
  }

  return null;
};

export const hasUserData = () => {
  return !!localStorage.getItem(endpoints.ls_name);
};

export const isTokenExpired = () => {
  const token = getUserData();
  if (!token || !token.exp_date) {
    return true;
  }

  const now = new Date();
  const tokenExpirationDate = new Date(token.exp_date);

  return now > tokenExpirationDate;
};
