import React, { useState, useMemo } from "react";
import styled from "styled-components";
import moment from "moment";
import useSWR from "swr";
import { Table } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  Layout,
  AppBar,
  Button,
  EditButton,
  Modal,
  InputText,
  DatePicker,
  Select,
} from "UI";
import withLabel from "Helpers/withLabel";
import { fetcher } from "Utils/request";
// import { Select } from 'On'

import "./index.less";

const SelectMatchType = styled.select`
  color: white;
  background-color: #202225;
  padding: 3px 3px 3px 3px;
`;

const SelectMatchOption = styled.option`
  color: white;
  padding: 3px 3px 3px 3px;
`;

const { Column } = Table;

const MatchList = () => {
  const matchesTypesList = [
    {
      name: "Inplay",
      value: "inplay",
    },
    {
      name: "Upcoming",
      value: "upcoming",
    },
    {
      name: "All",
      value: "all",
    },
  ];

  const sportTypes = [
    {
      name: "all",
      value: -1,
    },
    {
      name: "tennis",
      value: 13,
    },
    {
      name: "soccer",
      value: 1,
    },
    {
      name: "basketball",
      value: 18,
    },
    {
      name: "volleyball",
      value: 91,
    },
    {
      name: "handball",
      value: 78,
    },
    {
      name: "baseball",
      value: 16,
    },
    {
      name: "ice hockey",
      value: 17,
    },
    {
      name: "snooker",
      value: 14,
    },
    {
      name: "american football",
      value: 12,
    },
    {
      name: "cricket",
      value: 3,
    },
    {
      name: "futsal",
      value: 83,
    },
    {
      name: "darts",
      value: 15,
    },
    {
      name: "table tennis",
      value: 92,
    },
    {
      name: "badminton",
      value: 94,
    },
    {
      name: "rugby union",
      value: 8,
    },
    {
      name: "rugby league",
      value: 19,
    },
    {
      name: "australian rules",
      value: 36,
    },
    {
      name: "bowls",
      value: 66,
    },
    {
      name: "boxing/ufc",
      value: 9,
    },
    {
      name: "gaelic sports",
      value: 75,
    },
    {
      name: "floorball",
      value: 90,
    },
    {
      name: "beach volleyball",
      value: 95,
    },
    {
      name: "water polo",
      value: 110,
    },
    {
      name: "squash",
      value: 107,
    },
    {
      name: "e-sports",
      value: 151,
    },
  ];

  const [selectedMatchType, setSelectedMatchType] = useState(matchesTypesList[0].value);

  const [showModal, setShowModal] = useState(false);
  const [matchName, setMatchName] = useState("");
  const [matchId, setMatchId] = useState("");
  const [filter, setFilter] = useState("");
  const [selectedSportType, setSelectedSportType] = useState(sportTypes[0]);

  const { data: matchListFilter } = useSWR("/matches/filterbytype", fetcher);

  const matchDataFilter = useMemo(
    () =>
      !matchListFilter
        ? {
            upcoming: [],
            inplay: [],
            all: [],
          }
        : {
            upcoming: matchListFilter.upcoming.map((val, index) => ({
              ...val,
              key: index,
            })),
            inplay: matchListFilter.inplay.map((val, index) => ({ ...val, key: index })),
            all: [
              ...matchListFilter.inplay.map((val, index) => ({ ...val, key: index })),
              ...matchListFilter.upcoming.map((val, index) => ({
                ...val,
                key: index,
              })),
            ],
          },
    [matchListFilter]
  );

  const matchListStack = (
    <div className="onyx-breadcrumb text-gray">
      <span>Matches</span>
    </div>
  );

  const onChangeDate = (date, dateString) => {
    console.log(date, dateString);
  };

  const sportTypeFilter = (match) => {
    // Show all
    if (selectedSportType.value === -1) {
      return true;
    }
    return match.sport_id === selectedSportType.value;
  };

  return (
    <Layout menuSelected={1}>
      {showModal && (
        <Modal title="Create a Match" onClose={() => setShowModal(false)}>
          <div className="match-modal-row">
            <div className="col-left">
              {withLabel(
                <InputText
                  value={matchName}
                  onChange={(e) => setMatchName(e.target.value)}
                  placeholder="xxx VS xxx"
                />,
                "Match Name"
              )}
            </div>
            <div className="col-right">
              {withLabel(
                <InputText
                  value={matchId}
                  onChange={(e) => setMatchId(e.target.value)}
                  placeholder="MR******"
                />,
                "Match Id"
              )}
            </div>
          </div>
          <div className="match-modal-row">
            <div className="col-left">
              {withLabel(<DatePicker onChange={onChangeDate} />, "DATE")}
            </div>
            <div className="col-right"></div>
          </div>
          <div className="match-modal-footer">
            <Button className="col-left" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button className="col-right" primary>
              <PlusOutlined className="mr-1" />
              Create Match
            </Button>
          </div>
        </Modal>
      )}
      <AppBar
        title="Match Calendar"
        stackNavigator={matchListStack}
        style={{ background: "red" }}
      >
        <div className="onyx-flex-layout" style={{ alignItems: "flex-start" }}>
          {/* <SelectMatchType
            value={selectedMatchType}
            onChange={(e) => setSelectedMatchType(e.target.value)}
          >
            {matchesTypesList.map((matchtype) => (
              <SelectMatchOption onKeyDown={matchtype.value} value={matchtype.value}>
                {matchtype.name}
              </SelectMatchOption>
            ))}
          </SelectMatchType> */}
          {/* <div style={{ width: "300px", margin: "0 10px" }}>
            <Select
              placeholder="Select Sport"
              showSearch={true}
              value={selectedMatchType}
              onChange={(e) => {
                setSelectedMatchType(e.toLowerCase());
              }}
              options={matchesTypesList.map((matchtype) => ({
                label: matchtype.name,
                value: matchtype.name,
              }))}
            />
          </div> */}
          <div style={{ width: "300px", margin: "0 10px" }}>
            <Select
              key="2"
              placeholder="Select Sponsor"
              showSearch={true}
              value={selectedSportType.label}
              onChange={(e) => {
                setSelectedSportType(
                  sportTypes.find((sportType) => sportType.value === e)
                );
              }}
              options={sportTypes.map((sportType) => ({
                label: sportType.name,
                value: sportType.value,
              }))}
            />
          </div>
          <div style={{ width: "300px", margin: "0 10px" }}>
            <Select
              key="1"
              placeholder="Select Sponsor"
              showSearch={true}
              value={selectedMatchType}
              onChange={(e) => {
                setSelectedMatchType(e.toLowerCase());
              }}
              options={matchesTypesList.map((matchtype) => ({
                label: matchtype.name,
                value: matchtype.name,
              }))}
            />
          </div>
          <InputText
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            placeholder="Filter"
          />
          <Button
            primary
            onClick={() => setShowModal(true)}
            className="ml-8"
            style={{ whiteSpace: "nowrap" }}
          >
            <PlusOutlined className="mr-1" />
            CREATE MATCH
          </Button>
        </div>
      </AppBar>
      <Table
        dataSource={matchDataFilter[selectedMatchType]
          .filter(sportTypeFilter)
          .filter((match) => match.name.toLowerCase().includes(filter.toLowerCase()))}
      >
        <Column title="MATCH TITLE" dataIndex="name" key="name" />
        <Column title="MATCH ID" dataIndex="match_api_id" key="match_api_id" />
        <Column
          title="DATE"
          dataIndex="createdAt"
          key="createdAt"
          render={(ts) => <div>{moment(ts).format("MMMM Do, YYYY")}</div>}
        />
        <Column
          title="DETAILS"
          key="x"
          dataIndex="id"
          render={(id) => <EditButton to={`/matches/${id}`}>Edit Match</EditButton>}
        />
        {/* <Column
          title="TYPE"
          key="x"
          dataIndex="type"
        /> */}
        <Column title="PROVIDER" key="x" dataIndex="provider" />
      </Table>
    </Layout>
  );
};

export default MatchList;
