import React from "react";
import "./index.less";

const MenuItem = ({ isSelected, children, style, onClick }) => {
  return (
    <div
      className={`onyx-menu-item ${isSelected ? "menu-item-selected" : ""}`}
      style={style}
      onClick={onClick}
    >
      <span className="menu-text text-white">{children}</span>
    </div>
  );
};

export default MenuItem;
