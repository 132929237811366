import React from "react";
import { Link } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import "./index.less";

const DeleteButton = ({ children, style, to, onClick }) => {
  return onClick ? (
    <div className="edit-button-wrapper" style={style} onClick={onClick}>
      <div className="edit-button primary-gradient">
        <CloseOutlined />
      </div>
      {children}
    </div>
  ) : (
    <Link className="edit-button-wrapper" style={style} to={to}>
      <div className="edit-button primary-gradient">
        <CloseOutlined />
      </div>
      {children}
    </Link>
  );
};

export default DeleteButton;
