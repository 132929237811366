import React from "react";
import "./index.less";

const Card = ({ children, style, className, title, right }) => {
  return (
    <div className={`onyx-card ${className ? className : ""}`} style={style}>
      {title && (
        <div className="onyx-card-header">
          <div className="onyx-card-header-title">{title}</div>
          {right}
        </div>
      )}
      {children}
    </div>
  );
};

export default Card;
