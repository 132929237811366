import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { CloseCircleOutlined } from "@ant-design/icons";
import "./index.less";
import { prominent } from "color.js";
import { Player } from "video-react";
import getEnvEndpoints from "Config/endpoints";

const Dropzone = ({
  style,
  className,
  files,
  setFiles,
  type = "image",
  setColorsLogo,
  sponsor,
  dropType,
}) => {
  const defaultImg =
    "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQg2nmh1Hv_eJUNeBa60GrJ2XvhGUIwGAbWiQ&usqp=CAU";

  const { getRootProps, getInputProps } = useDropzone({
    // accept: `${type}/*`,
    accept: `image/*, video/*`,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });
  console.log("files", files);
  const onReset = () => {
    setFiles([]);
  };

  useEffect(() => {
    // prominent(defaultImg, { amount: 4, format: 'hex' }).then(color => {
    //     setColorsLogo && setColorsLogo({
    //       primaryLogo: color[0] && color[0],
    //       secondaryLogo: color[1] && color[1],
    //       strokeLogo: color[2] && color[2],
    //       backgroundLogo: color[3] && color[3],
    //     });
    //   });
    files[0] &&
      prominent(files[0].preview, { amount: 4, format: "hex" }).then((color) => {
        setColorsLogo &&
          setColorsLogo({
            primaryLogo: color[0] && color[0],
            secondaryLogo: color[1] && color[1],
            strokeLogo: color[2] && color[2],
            backgroundLogo: color[3] && color[3],
          });
      });
  }, [files, setColorsLogo]);

  return (
    <div className="onyx-dropzone" style={style}>
      <div
        {...getRootProps({
          className: `dropzone ${className ? className : ""}`,
        })}
      >
        {files.length === 0 ? (
          <>
            <input {...getInputProps()} />
            <img src="/images/upload.png" alt="upload" />
            <p>Drag a file here or browse a file to upload</p>
          </>
        ) : (
          <>
            <>
              {dropType === "logoType" ? (
                <>
                  <img src={files[0]?.preview} alt="preview" />
                  <CloseCircleOutlined
                    onClick={onReset}
                    className="text-white reset-icon"
                  />
                </>
              ) : (
                <>
                  {files[0]?.type?.includes("video") ||
                  (sponsor?.video_intro && sponsor?.video_intro.includes("mp4")) ||
                  (sponsor?.video_end && sponsor?.video_end.includes("mp4")) ? (
                    dropType === "IntroVideo" && files[0]?.type?.includes("video") ? (
                      <>
                        <Player>
                          {files[0].preview ? (
                            <source src={files[0].preview} />
                          ) : sponsor?.video_intro && dropType === "IntroVideo" ? (
                            <source
                              src={
                                getEnvEndpoints()?.baseApiUrl + "/" + sponsor.video_intro
                              }
                            />
                          ) : (
                            ""
                          )}
                        </Player>
                        <CloseCircleOutlined
                          onClick={onReset}
                          className="text-white reset-icon"
                        />
                      </>
                    ) : dropType === "EndVideo" && files[0]?.type?.includes("video") ? (
                      <>
                        <Player>
                          {files[0].preview ? (
                            <source src={files[0].preview} />
                          ) : sponsor?.video_end && dropType === "EndVideo" ? (
                            <source
                              src={
                                getEnvEndpoints()?.baseApiUrl + "/" + sponsor.video_end
                              }
                            />
                          ) : (
                            ""
                          )}
                        </Player>
                        <CloseCircleOutlined
                          onClick={onReset}
                          className="text-white reset-icon"
                        />
                      </>
                    ) : dropType === "IntroVideo" &&
                      sponsor?.video_intro.includes("mp4") &&
                      !files[0].type ? (
                      <>
                        <Player>
                          {files[0].preview ? (
                            <source src={files[0].preview} />
                          ) : sponsor?.video_intro && dropType === "IntroVideo" ? (
                            <source
                              src={
                                getEnvEndpoints()?.baseApiUrl + "/" + sponsor.video_intro
                              }
                            />
                          ) : (
                            ""
                          )}
                        </Player>
                        <CloseCircleOutlined
                          onClick={onReset}
                          className="text-white reset-icon"
                        />
                      </>
                    ) : dropType === "EndVideo" &&
                      sponsor?.video_end.includes("mp4") &&
                      !files[0].type ? (
                      <>
                        <Player>
                          {files[0].preview ? (
                            <source src={files[0].preview} />
                          ) : sponsor?.video_end && dropType === "EndVideo" ? (
                            <source
                              src={
                                getEnvEndpoints()?.baseApiUrl + "/" + sponsor.video_end
                              }
                            />
                          ) : (
                            ""
                          )}
                        </Player>
                        <CloseCircleOutlined
                          onClick={onReset}
                          className="text-white reset-icon"
                        />
                      </>
                    ) : (
                      <>
                        <img src={files[0].preview} alt="preview" />
                        <CloseCircleOutlined
                          onClick={onReset}
                          className="text-white reset-icon"
                        />
                      </>
                    )
                  ) : (
                    <>
                      <img src={files[0].preview} alt="preview" />
                      <CloseCircleOutlined
                        onClick={onReset}
                        className="text-white reset-icon"
                      />
                    </>
                  )}
                </>
              )}
            </>
          </>
        )}
        {/* <img src={files[0].preview} alt="preview" />
            <CloseCircleOutlined onClick={onReset} className="text-white reset-icon" />
          </>
        )} */}
      </div>
    </div>
  );
};

export default Dropzone;
