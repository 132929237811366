import React, { useState, useMemo, useEffect } from "react";
import moment from "moment";
import { mutate } from "swr";
import { Table } from "antd";
import { DollarCircleOutlined } from "@ant-design/icons";
import { Layout, AppBar, Button, EditButton, Modal, InputText, Dropzone } from "UI";
import withLabel from "Helpers/withLabel";
import { makeRequest } from "Utils/request";
import DeleteButton from "../../UI/DeleteButton";
import "./index.less";

const { Column } = Table;

const SponsorList = ({ sponsorList }) => {
  const [files, setFiles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [sponsorName, setSponsorName] = useState("");
  const [website, setWebsite] = useState("");
  const [sponsorData, setSponsorData] = useState("");

  // const sponsorData = useMemo(() => {
  //   return !sponsorList ? [] : sponsorList.map((val) => ({ ...val, key: val.id }));
  // }, [sponsorList]);

  useEffect(() => {
    const sponsorInfo = (sponsorList || []).map((val) => ({
      ...val,
      key: val.id,
    }));

    setSponsorData(sponsorInfo);
  }, [sponsorList]);

  const sponsorListStack = (
    <div className="onyx-breadcrumb text-gray">
      <span>Sponsor</span>
    </div>
  );

  const onDeleteUser = async (sponsor_id) => {
    const filtredData = sponsorData.filter((sponsor) => sponsor.id !== sponsor_id);
    setSponsorData(filtredData);
    makeRequest("delete", `sponsors/${sponsor_id}`);
  };

  const onAddSponsor = async () => {
    if (sponsorName !== "") {
      let formData = new FormData();
      formData.set("name", sponsorName);
      formData.set("website", website);
      formData.set("avatar", files.length === 0 ? null : files[0]);
      const newSponsor = await makeRequest("post", "/sponsors", formData);
      if (newSponsor) {
        mutate("/sponsors/all", [...sponsorList, newSponsor], false);
      }
      await resetSponsorModal();
    }
  };

  const resetSponsorModal = () => {
    setSponsorName("");
    setWebsite("");
    setFiles([]);
    setShowModal(false);
  };

  return (
    <Layout menuSelected={0}>
      {showModal && (
        <Modal title="Add Sponsor" onClose={() => setShowModal(false)}>
          <div className="sponsor-modal-row">
            {withLabel(
              <InputText
                value={sponsorName}
                onChange={(e) => setSponsorName(e.target.value)}
                placeholder="Sponsors Name"
              />,
              "Name"
            )}
          </div>
          <div className="sponsor-modal-row">
            <div className="col-left">
              {withLabel(
                <Dropzone className="py-2" files={files} setFiles={setFiles} />,
                "Logo"
              )}
            </div>
            <div className="col-right">
              {withLabel(
                <InputText
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                  placeholder="www.sponsor.com"
                />,
                "Website"
              )}
            </div>
          </div>
          <div className="sponsor-modal-footer">
            <Button className="col-left" onClick={resetSponsorModal}>
              Cancel
            </Button>
            <Button className="col-right" primary onClick={onAddSponsor}>
              <DollarCircleOutlined className="mr-1" />
              Add Sponsor
            </Button>
          </div>
        </Modal>
      )}
      <AppBar title="Welcome" stackNavigator={sponsorListStack}>
        <Button primary onClick={() => setShowModal(true)}>
          <DollarCircleOutlined className="mr-1" />
          Add Sponsor
        </Button>
      </AppBar>
      <Table dataSource={sponsorData} pagination={false}>
        <Column title="NAME" dataIndex="name" key="name" />
        <Column
          title="CREATED"
          dataIndex="createdAt"
          key="createdAt"
          render={(ts) => <div>{moment(ts).format("MMMM Do, YYYY")}</div>}
        />
        <Column
          title="DETAILS"
          key="x"
          dataIndex="id"
          render={(index) => (
            <EditButton to={`/sponsors/${index}`}>Edit Sponsor</EditButton>
          )}
        />
        <Column
          title="OPTIONS"
          key="x"
          dataIndex="id"
          render={(id) => (
            <DeleteButton
              onClick={() => {
                onDeleteUser(id);
              }}
            >
              Delete sponsor
            </DeleteButton>
          )}
        />
      </Table>
    </Layout>
  );
};

export default SponsorList;
