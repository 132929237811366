import React from "react";
import "./index.less";

const Button = ({ children, style, className, onClick, primary, textStyle }) => {
  return (
    <div
      className={`onyx-button ${primary ? "primary-gradient" : "secondary-gradient"} ${
        className ? className : ""
      }`}
      style={style}
      onClick={onClick}
    >
      <span className="onyx-button-text uppercase text-white" style={textStyle}>
        {children}
      </span>
    </div>
  );
};

export default Button;
