import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { Layout as Wrapper } from "antd";
import { clearUserData } from "Utils/persist";

import "./index.less";

const Logout = ({ onSuccess }) => {
  const history = useHistory();

  useEffect(() => {
    const onLogout = async () => {
      await clearUserData();
      await onSuccess();
      await history.push("/login");
    };

    onLogout();
  }, [history, onSuccess]);
  return <Wrapper className="onyx-layout"></Wrapper>;
};

export default Logout;
