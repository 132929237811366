import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import "./index.less";

const Modal = ({ children, title, onClose, width = 600 }) => {
  return (
    <div className="onyx-modal" style={{ width: `${width}px` }}>
      <div className="modal-header">
        <div className="modal-header-text">{title}</div>
        <CloseOutlined onClick={onClose} className="text-white" />
      </div>
      <div className="modal-content">{children}</div>
    </div>
  );
};

export default Modal;
