import axios from "axios";
import { getToken } from "./persist";
import getEnvEndpoints from "../Config/endpoints";

const endpoints = getEnvEndpoints();

const axiosInstance = axios.create({
  baseURL: endpoints.baseApiUrl,
});

const parseErrors = (err) => {
  console.error(err.response);
  if (err.response) {
    switch (err.response.status) {
      case 401:
        const path = `${window.location.pathname}${window.location.hash}`;
        window.location = `/login?back=${path}`;
        break;
      default:
        throw err.response.data;
    }
  } else if (err.request) {
    console.error(err, "network error");
  } else {
    console.error("something unique happened");
  }
};

const createHeaders = (additionalHeaders = {}) => {
  const headers = {
    "Content-Type": "application/json",
    ...additionalHeaders,
  };

  const token = getToken();
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return headers;
};

export const makeRequest = async (method, url, data = {}, additionalHeaders = {}) => {
  const headers = createHeaders(additionalHeaders);

  try {
    const res = await axiosInstance({
      method,
      url,
      data,
      headers,
    });
    return res.data;
  } catch (err) {
    parseErrors(err);
  }
};

export const fetcher = async (path, method = "get") => {
  try {
    const headers = createHeaders();
    const res = await axiosInstance[method](path, { headers });
    return res.data;
  } catch (err) {
    parseErrors(err);
  }
};

export const anonymousFetcher = async (path) => {
  try {
    const res = await axiosInstance.get(path);
    return res.data;
  } catch (err) {
    parseErrors(err);
  }
};
