import React, { useState, useEffect } from "react";
import moment from "moment";
import { Table } from "antd";
import { mutate } from "swr";
import { UserAddOutlined } from "@ant-design/icons";
import { Layout, AppBar, Button, EditButton, Modal, InputText, Select } from "UI";
import DeleteButton from "../../UI/DeleteButton";
import withLabel from "Helpers/withLabel";
import { sponsorList, permissionList } from "Config/dummy";
import { makeRequest } from "Utils/request";

import "./index.less";

const { Column } = Table;

const UsersList = ({ userList }) => {
  const [showModal, setShowModal] = useState(false);
  const [firstName, setFirstname] = useState("");
  const [lastName, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [sponsor, setSponsor] = useState(-1);
  const [permission, setPermission] = useState(-1);
  const [userData, setUserData] = useState(null);

  const permissionData = permissionList.map((val) => ({
    label: val.permission,
    value: val.id,
  }));
  const sponsorData = sponsorList.map((val) => ({
    label: val.name,
    value: val.id,
  }));

  useEffect(() => {
    const userInfo = (userList || []).map((val, index) => ({
      ...val,
      key: index,
      name: `${val.firstName} ${val.lastName}`,
    }));
    setUserData(userInfo);
  }, [userList]);

  const userListStack = (
    <div className="onyx-breadcrumb text-gray">
      <span>Users</span>
    </div>
  );

  const onCreateUser = async () => {
    const newUser = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      sponsor: sponsor,
      permission: permission,
    };
    const createdUser = await makeRequest("post", "/users", newUser);
    if (createdUser) {
      mutate("/users/all", [...userList, createdUser], false);
    }
    await setShowModal(false);
  };

  const onDeleteUser = async (user_id) => {
    const filtredData = userData.filter((user) => user.id !== user_id);
    setUserData(filtredData);
    makeRequest("delete", `users/${user_id}`);
  };

  return (
    <Layout menuSelected={2}>
      {showModal && (
        <Modal title="Create a User" onClose={() => setShowModal(false)}>
          <div className="user-modal-row">
            <div className="col-left">
              {withLabel(
                <InputText
                  value={firstName}
                  onChange={(e) => setFirstname(e.target.value)}
                  placeholder="First Name"
                />,
                "Firstname"
              )}
            </div>
            <div className="col-right">
              {withLabel(
                <InputText
                  value={lastName}
                  onChange={(e) => setLastname(e.target.value)}
                  placeholder="Last Name"
                />,
                "Lastname"
              )}
            </div>
          </div>
          <div className="user-modal-row">
            {withLabel(
              <InputText
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
              />,
              "Email"
            )}
          </div>
          <div className="user-modal-row">
            <div className="col-left">
              {withLabel(
                <Select
                  placeholder="Select Sponsor"
                  showSearch={true}
                  onChange={(e) => setSponsor(e)}
                  options={sponsorData}
                />,
                "Sponsor"
              )}
            </div>
            <div className="col-right">
              {withLabel(
                <Select
                  placeholder="Select Permission"
                  showSearch={false}
                  onChange={(e) => setPermission(e)}
                  options={permissionData}
                />,
                "Permission"
              )}
            </div>
          </div>
          <div className="user-modal-footer">
            <Button className="col-left" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button className="col-right" primary onClick={onCreateUser}>
              <UserAddOutlined className="mr-1" />
              Create User
            </Button>
          </div>
        </Modal>
      )}
      <AppBar title="Complete List of Users" stackNavigator={userListStack}>
        <Button primary onClick={() => setShowModal(true)}>
          <UserAddOutlined className="mr-1" />
          CREATE USER
        </Button>
      </AppBar>
      <Table dataSource={userData} pagination={false}>
        <Column title="NAME" dataIndex="name" key="name" />
        <Column
          title="EMAIL"
          dataIndex="email"
          key="email"
          // render={(sponsorId) => sponsorList.find(({ id }) => id === sponsorId).name}
        />
        <Column
          title="JOINED"
          dataIndex="created_at"
          key=""
          render={(ts) => <div>{moment().format("MMMM Do, YYYY")}</div>}
        />
        <Column
          title="DETAILS"
          key="x"
          dataIndex="id"
          render={(index) => <EditButton to={`/users/${index}`}>More Details</EditButton>}
        />

        <Column
          title="OPTIONS"
          key="x"
          dataIndex="id"
          render={(id) => (
            <DeleteButton
              onClick={() => {
                onDeleteUser(id);
              }}
            >
              Delete user
            </DeleteButton>
          )}
        />
      </Table>
    </Layout>
  );
};

export default UsersList;
