import React from "react";
import "./index.less";

const Subtitle = ({ children, style, className }) => {
  if (Array.isArray(children)) {
    return <div />;
  }
  return (
    <div className={`onyx-subtitle ${className ? className : ""}`} style={style}>
      {Array.isArray(children) ? children.join(" ") : children}
    </div>
  );
};

export default Subtitle;
