import React, { useState, useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import useSWR, { mutate } from "swr";
import { Table } from "antd";
import {
  Layout,
  AppBar,
  Button,
  Dropzone,
  Card,
  ColorPicker,
  EditButton,
  GoogleMap,
  InputText,
} from "UI";
import withLabel from "Helpers/withLabel";
import { fetcher, makeRequest } from "Utils/request";
import getEnvEndpoints from "../../Config/endpoints";

import "./index.less";

const { Column } = Table;

const SponsorEdit = ({ sponsorList }) => {
  const { id: sponsorId } = useParams();
  const [loading, setLoading] = useState(false);
  const [sponsor, setSponsor] = useState({});
  const [filter, setFilter] = useState("");

  const [logoFiles, setLogoFiles] = useState([]);
  const [introFiles, setIntroFiles] = useState([]);
  const [endFiles, setEndFiles] = useState([]);
  const [website, setWebsite] = useState("");
  const [colorsLogo, setColorsLogo] = useState({
    primaryLogo: "",
    secondaryLogo: "",
    strokeLogo: "",
    backgroundLogo: "",
  });
  const [colors, setColors] = useState({
    primary: sponsor ? sponsor.color_primary || "" : "",
    secondary: sponsor ? sponsor.color_secondary || "" : "",
    stroke: sponsor ? sponsor.color_stroke || "" : "",
    background: sponsor ? sponsor.color_background || "" : "",
  });

  const { data: matchesList } = useSWR("/matches/all", fetcher, { refreshInterval: 0 });
  const { data: sponsorMatchesList } = useSWR(`/sponsors/matches/${sponsorId}`, fetcher, {
    refreshInterval: 0,
  });

  const [matchesState, setMatchesState] = useState([]);
  const [sponsorMatchesState, setSponsorMatchesState] = useState([]);

  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    if (matchesList && sponsorMatchesList && !dataFetched) {
      const filteredSponsorMatches = sponsorMatchesList?.map((el) => {
        return {
          ...el,
          name: el.Match.name,
          startTime: el.Match.start_time,
          matchId: el.Match.match_api_id,
          matchProvider: el.Match.provider,
        };
      });
      console.log("matchesList>>>>>", matchesList);
      const filteredMatches = matchesList.matches ? matchesList.matches : matchesList;
      filteredSponsorMatches &&
        filteredSponsorMatches.forEach((sponsorMatch) => {
          matchesList.matches
            ? matchesList.matches.forEach((match, matchId) => {
                if (
                  +sponsorMatch.sponsor_id === +sponsorId &&
                  +sponsorMatch.match_id === +match.id
                ) {
                  filteredMatches.splice(matchId, 1);
                }
              })
            : matchesList.forEach((match, matchId) => {
                if (
                  +sponsorMatch.sponsor_id === +sponsorId &&
                  +sponsorMatch.match_id === +match.id
                ) {
                  filteredMatches.splice(matchId, 1);
                }
              });
        });
      setMatchesState(filteredMatches);
      setSponsorMatchesState(filteredSponsorMatches);
      setDataFetched(true);
    }
  }, [dataFetched, matchesList, sponsorId, sponsorMatchesList]);

  useEffect(() => {
    if (loading === false && sponsorList && sponsorList.length > 0) {
      const currentSponsor = sponsorList.find((s) => s.id === parseInt(sponsorId));
      setSponsor(currentSponsor);
      setColors({
        primary: currentSponsor.color_primary || "",
        secondary: currentSponsor.color_secondary || "",
        stroke: currentSponsor.color_stroke || "",
        background: currentSponsor.color_background || "",
      });

      if (currentSponsor.avatar) {
        setLogoFiles([
          {
            preview: getEnvEndpoints().baseApiUrl + "/" + currentSponsor.avatar,
          },
        ]);
      }
      if (currentSponsor.video_intro) {
        setIntroFiles([
          {
            preview: getEnvEndpoints().baseApiUrl + "/" + currentSponsor.video_intro,
          },
        ]);
      }
      if (currentSponsor.video_end) {
        setEndFiles([
          {
            preview: getEnvEndpoints().baseApiUrl + "/" + currentSponsor.video_end,
          },
        ]);
      }
      if (currentSponsor.website) {
        setWebsite(currentSponsor.website);
      }
      setLoading(true);
    }
  }, [loading, sponsorId, sponsorList]);

  if (!sponsor || !loading) {
    return <Layout menuSelected={0}>Loading...</Layout>;
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows);

      selectedRows.forEach((el) => {
        setMatchesState(
          matchesState.matches
            ? matchesState.matches.filter((match) => match.id !== el.id)
            : matchesState.filter((match) => match.id !== el.id)
        );
        setSponsorMatchesState([
          ...sponsorMatchesState,
          {
            ...el,
            name: el.name,
            startTime: el.start_time,
            matchId: el.match_api_id,
            match_id: el.id,
            matchProvider: el.provider,
          },
        ]);

        makeRequest("post", `matches/connect-sponsor`, {
          // countryCode,
          matchId: el.id,
          sponsorId,
        });
      });
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };

  const handlerDisconnectMatch = (match_id) => {
    console.log("sponsorMatchesState>>>", sponsorMatchesState, match_id);
    setSponsorMatchesState(
      sponsorMatchesState.filter((match) => match.match_id !== match_id)
    );

    makeRequest("delete", `sponsors/disconnect-match/${match_id}/${sponsorId}`);
  };

  const sponsorEditStack = (
    <div className="onyx-breadcrumb">
      <Link to="/" className="text-gray">
        Sponsor
      </Link>
      <span className="text-white">{" > "}</span>
      <span className="text-gray">Edit Sponsor</span>
    </div>
  );

  const onUpdateSponsor = async () => {
    let formData = new FormData();
    formData.set("id", sponsorId);
    formData.set("avatar", logoFiles.length === 0 ? null : logoFiles[0]);
    colors.primary !== ""
      ? formData.set("color_primary", colors.primary)
      : formData.set("color_primary", colorsLogo.primaryLogo);
    colors.secondary !== ""
      ? formData.set("color_secondary", colors.secondary)
      : formData.set("color_secondary", colorsLogo.secondaryLogo);
    colors.stroke !== ""
      ? formData.set("color_stroke", colors.stroke)
      : formData.set("color_stroke", colorsLogo.strokeLogo);
    colors.background !== ""
      ? formData.set("color_background", colors.background)
      : formData.set("color_background", colorsLogo.backgroundLogo);
    formData.set("website", website);
    const updatedSponsor = await makeRequest("put", "/sponsors", formData);
    if (updatedSponsor) {
      const updatedSponsorList = sponsorList.slice();
      updatedSponsorList[
        updatedSponsorList.findIndex((s) => s.id === parseInt(sponsorId))
      ] = updatedSponsor;
      mutate("/sponsors/all", updatedSponsorList, false);
    }

    let formDataVideoStart = new FormData();
    formDataVideoStart.set("video_end", endFiles.length === 0 ? null : endFiles[0]);
    makeRequest("put", `/sponsors/video-end/${sponsorId}`, formDataVideoStart).then(
      (response) => {
        if (updatedSponsor) {
          const updatedSponsorList = sponsorList.slice();
          updatedSponsorList[
            updatedSponsorList.findIndex((s) => s.id === parseInt(sponsorId))
          ] = updatedSponsor;
          mutate("/sponsors/all", updatedSponsorList, false);
        }
      }
    );

    let formDataVideoEnd = new FormData();
    formDataVideoEnd.set("video_intro", introFiles.length === 0 ? null : introFiles[0]);
    makeRequest("put", `/sponsors/video-intro/${sponsorId}`, formDataVideoEnd).then(
      (response) => {
        if (updatedSponsor) {
          const updatedSponsorList = sponsorList.slice();
          updatedSponsorList[
            updatedSponsorList.findIndex((s) => s.id === parseInt(sponsorId))
          ] = updatedSponsor;
          mutate("/sponsors/all", updatedSponsorList, false);
        }
      }
    );
  };

  return (
    <Layout menuSelected={0}>
      <AppBar title={sponsor.name} stackNavigator={sponsorEditStack}>
        <Button primary onClick={onUpdateSponsor}>
          Save Edits
        </Button>
      </AppBar>
      <div className="onyx-flex-layout">
        <div className="col-1-3">
          <Card title="Logo">
            <Dropzone
              className="py-4"
              files={logoFiles}
              setFiles={setLogoFiles}
              setColorsLogo={setColorsLogo}
              dropType="logoType"
            />
          </Card>
          <Card title="Website">
            <InputText
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
              placeholder="www.sponsor.com"
            />
          </Card>
          <Card
            title="Video"
            right={
              <Link className="text-gray" to="https://google.com">
                See Example
              </Link>
            }
          >
            {withLabel(
              <Dropzone
                className="py-1"
                files={introFiles}
                setFiles={setIntroFiles}
                type="image"
                sponsor={sponsor}
                dropType="IntroVideo"
              />,
              "Intro"
            )}
            {withLabel(
              <Dropzone
                className="py-1"
                files={endFiles}
                setFiles={setEndFiles}
                type="image"
                sponsor={sponsor}
                dropType="EndVideo"
              />,
              "End"
            )}
          </Card>
        </div>
        <div className="col-2-3">
          <Card title="Colors">
            <div className="onyx-flex-layout">
              {withLabel(
                <ColorPicker
                  value={
                    colors.primary !== "" ? colors.primary : colorsLogo.primaryLogo || ""
                  }
                  onChange={(val) => setColors({ ...colors, primary: val })}
                />,
                "Primary"
              )}
              {withLabel(
                <ColorPicker
                  value={
                    colors.secondary !== ""
                      ? colors.secondary
                      : colorsLogo.secondaryLogo || ""
                  }
                  onChange={(val) => setColors({ ...colors, secondary: val })}
                />,
                "Secondary"
              )}
              {withLabel(
                <ColorPicker
                  value={
                    colors.stroke !== "" ? colors.stroke : colorsLogo.strokeLogo || ""
                  }
                  onChange={(val) => setColors({ ...colors, stroke: val })}
                />,
                "Stroke"
              )}
              {withLabel(
                <ColorPicker
                  value={
                    colors.background !== ""
                      ? colors.background
                      : colorsLogo.backgroundLogo || ""
                  }
                  onChange={(val) => setColors({ ...colors, background: val })}
                />,
                "Backgrounds"
              )}
            </div>
          </Card>
          <Card title="VIEW SPONSOR COVERAGE">
            {/* Google Map Integration Here!!! */}

            <div className="onyx-map-demo">
              <GoogleMap
                height={150}
                positions={[
                  {
                    lat: 9.761927,
                    lng: 79.95244,
                  },
                  {
                    lat: 9.762927,
                    lng: 79.95344,
                  },
                ]}
              />
            </div>

            {/* <img src="/images/map_demo.png" alt="map" className="onyx-map-demo" /> */}

            <Table
              dataSource={sponsorMatchesState}
              pagination={{ pageSize: 5 }}
              rowKey="id"
            >
              <Column
                title="ACTIVE"
                dataIndex="isActive"
                key="isActive"
                render={(isActive) => (
                  <div
                    className={
                      !isActive ? "onyx-game-circle active" : "onyx-game-circle inactive"
                    }
                  ></div>
                )}
              />
              <Column title="MATCH TITLE" dataIndex="name" key="name" />
              <Column title="MATCH ID" dataIndex="matchId" key="matchId" />
              <Column
                title="DATE"
                dataIndex="startTime"
                key="startTime"
                render={(ts) => <div>{moment.unix(ts).format("MMMM Do, YYYY")}</div>}
              />
              <Column
                title="DETAILS"
                key="x"
                dataIndex="match_id"
                render={(match_id) => (
                  <EditButton to={`/matches/${match_id}`}>Edit Match</EditButton>
                )}
              />
              <Column
                title="Disconnect"
                key="xx"
                dataIndex="match_id"
                render={(match_id) => (
                  <Button
                    primary
                    style={{ padding: 0, height: "55px", width: "70px" }}
                    textStyle={{ fontSize: "10px" }}
                    onClick={() => {
                      handlerDisconnectMatch(match_id);
                    }}
                  >
                    Disconnect Match
                  </Button>
                )}
              />
              <Column title="PROVIDER" dataIndex="matchProvider" key="matchProvider" />
            </Table>
          </Card>
          <Card
            title="AVAILABLE EVENTS"
            right={
              <InputText
                value={filter}
                className="w-16"
                onChange={(e) => setFilter(e.target.value)}
                placeholder="Filter"
              />
            }
          >
            <Table
              dataSource={
                matchesState.matches
                  ? matchesState.matches.filter((match) =>
                      match.name.toLowerCase().includes(filter.toLowerCase())
                    )
                  : matchesState.filter((match) =>
                      match.name.toLowerCase().includes(filter.toLowerCase())
                    )
              }
              pagination={{ pageSize: 5 }}
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              rowKey="id"
            >
              <Column title="MATCH TITLE" dataIndex="name" key="name" />
              <Column title="MATCH ID" dataIndex="match_api_id" key="match_api_id" />
              <Column
                title="DATE"
                dataIndex="start_time"
                key="start_time"
                render={(ts) => {
                  return <div>{moment.unix(ts).format("MMMM Do, YYYY")}</div>;
                }}
              />
              <Column title="PROVIDER" dataIndex="provider" key="provider" />
            </Table>
          </Card>
        </div>
      </div>
    </Layout>
  );
};

export default SponsorEdit;
