import React from "react";
import { Select } from "antd";

import "./index.less";

const OnyxSelect = ({ showSearch, placeholder, onChange, options, value }) => {
  return (
    <Select
      className="onyx-select"
      showSearch={showSearch}
      placeholder={placeholder}
      optionFilterProp="children"
      onChange={onChange}
      value={value}
      filterOption={(input, option) => {
        if (option.key && option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0) {
          return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }
        return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
      options={options}
    />
  );
};

export default OnyxSelect;
