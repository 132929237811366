import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  display: flex;
`;

const Label = styled.label`
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 25px;
  text-transform: capitalize;
  color: #ffffff;
  text-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
`;

export default function withLabel(dom, label, isCompact = false, isLeft = false) {
  return (
    <Wrapper
      style={
        !isLeft
          ? { marginBottom: isCompact ? 0 : "2rem", flexDirection: "column" }
          : { flexDirection: "row", justifyContent: "flex-end" }
      }
    >
      <Label
        title={label}
        style={
          !isLeft ? { marginBottom: isCompact ? 0 : "1rem" } : { marginRight: "1rem" }
        }
      >
        {label}
      </Label>
      {dom}
    </Wrapper>
  );
}
