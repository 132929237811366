import React from "react";
import "./index.less";

const ColorPicker = ({
  style,
  className,
  value,
  onChange,
  isPreview,
  type = "compact",
}) => {
  return (
    <div className={`onyx-color-picker ${className ? className : ""}`} style={style}>
      <div className="onyx-color-circle" style={{ backgroundColor: value }}></div>
      {!isPreview && (
        <input
          className="onyx-color-input"
          placeholder="#000000"
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          style={type === "default" ? { padding: "1rem", marginRight: 0 } : {}}
        />
      )}
    </div>
  );
};

export default ColorPicker;
