import React from "react";
import "./index.less";

const AppBar = ({ title, stackNavigator, children }) => {
  return (
    <div className="onyx-appbar">
      <div className="onyx-appbar-left">
        {stackNavigator}
        <div className="onyx-appbar-title text-white">{title}</div>
      </div>
      <div className="onyx-appbar-right">{children}</div>
    </div>
  );
};

export default AppBar;
