import React, { useState, useMemo, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { Layout, AppBar, Dropzone, Card, Button, InputText, Select } from "UI";
import withLabel from "Helpers/withLabel";
import { userList, permissionList, sponsorList } from "Config/dummy";
import useSWR, { mutate } from "swr";
import { fetcher, makeRequest } from "Utils/request";
import "./index.less";

const UsersEdit = () => {
  const { id: userId } = useParams();
  const { data: userData } = useSWR(`/users/${userId}`, fetcher);
  const [files, setFiles] = useState([]);
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [sponsor, setSponsor] = useState("");
  const [permission, setPermission] = useState("");
  const [phone, setPhone] = useState("");
  const userInfo = useMemo(
    () =>
      !userData
        ? {}
        : {
            email: userData.email,
            firstName: userData.firstName,
            lastName: userData.lastName,
            id: userData.id,
            sponsor: userData.sponsor_id,
            permission: userData.permission,
            phone: userData.phone,
          },
    [userData]
  );

  useEffect(() => {
    setEmail(userInfo.email);
    setFirstName(userInfo.firstName);
    setLastName(userInfo.lastName);
  }, [userInfo]);

  const permissionData = permissionList.map((val) => ({
    label: val.permission,
    value: val.id,
  }));
  const sponsorData = sponsorList.map((val) => ({
    label: val.name,
    value: val.id,
  }));

  const [user, setUser] = useState(userList.find((val) => val.id === parseInt(userId)));

  const usersEditStack = (
    <div className="onyx-breadcrumb">
      <Link to="/users" className="text-gray">
        Users
      </Link>
      <span className="text-white">{" > "}</span>
      <span className="text-gray">Edit Users</span>
    </div>
  );

  const onUpdateUser = async () => {
    const newUser = {
      id: userInfo.id,
      lastName: lastName,
      firstName: firstName,
      permission: permission,
      sponsor: sponsor,
      email: email,
      phone: phone,
    };
    const updatedUser = await makeRequest("put", "/users", newUser);
    mutate(`/users/${userId}`, updatedUser, false);
  };

  return (
    <Layout menuSelected={2}>
      <AppBar title={`${firstName} ${lastName}`} stackNavigator={usersEditStack}>
        <div className="onyx-flex-layout">
          <Button className="mr-2" onClick={() => {}}>
            Reset Password
          </Button>
          <Button primary onClick={onUpdateUser}>
            Save Edits
          </Button>
        </div>
      </AppBar>
      <Card>
        <div className="onyx-flex-layout">
          <div className="col-1-3">
            <Dropzone className="py-12" files={files} setFiles={setFiles} />
          </div>
          <div className="col-1-3">
            <div className="onyx-flex-layout">
              <div className="col-left">
                {withLabel(
                  <InputText
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder="FirstName"
                  />,
                  "First Name"
                )}
              </div>
              <div className="col-right">
                {withLabel(
                  <InputText
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    placeholder="LastName"
                  />,
                  "Last Name"
                )}
              </div>
            </div>
            {withLabel(
              <InputText
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Contact Number"
              />,
              "Contact Number"
            )}
            {withLabel(
              <InputText
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
              />,
              "Email"
            )}
          </div>

          <div className="col-1-3">
            {withLabel(
              <Select
                placeholder="Select Sponsor"
                showSearch={true}
                value={user.sponsorId}
                onChange={(e) => console.log(e)}
                options={sponsorData}
              />,
              "Sponsor"
            )}
            {withLabel(
              <Select
                placeholder="Select Permission"
                showSearch={false}
                value={user.permission}
                onChange={(e) => console.log(e)}
                options={permissionData}
              />,
              "Permission"
            )}
          </div>
        </div>
      </Card>
    </Layout>
  );
};

export default UsersEdit;
