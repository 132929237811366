import React, { useState, useMemo, useEffect } from "react";
import { Link, useParams, Redirect } from "react-router-dom";
import moment from "moment";
import useSWR from "swr";
import { Table } from "antd";
import { makeRequest } from "Utils/request";
import { UpOutlined, DownOutlined, CloseOutlined } from "@ant-design/icons";
import {
  Layout,
  AppBar,
  Button,
  Dropzone,
  Modal,
  Card,
  ColorPicker,
  Detail,
  EditButton,
  InputText,
  Select,
  PlaceAutoComplete,
} from "UI";
import { placesAvailable } from "UI/PlaceAutoComplete";
import withLabel from "Helpers/withLabel";
import { fetcher } from "Utils/request";
import { oddsMakerList, matchList, playerList, sponsorList } from "Config/dummy";
import getEnvEndpoints from "Config/endpoints";

import "./index.less";

const { Column } = Table;

const MatchesEdit = () => {
  const params = useParams();
  const { id: matchId } = useParams();

  const [showSponsorModal, setShowSponsorModal] = useState(false);
  const [selectedSponsor, setSelectedSponsor] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [showPlayerModal, setShowPlayerModal] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(-1);

  let { data: matchData, mutate } = useSWR(`/matches/${matchId}`, fetcher, {
    refreshInterval: 180000,
  });

  const [match, setMatch] = useState([]);
  const [players, setPlayers] = useState(playerList);

  const groupMatchData = () => {
    const matchesInUse = [];
    const sponsorsInUse = [];

    const result = matchData.sponsors.filter((el) => {
      // if (!matchesInUse.includes(el.match_id) && !sponsorsInUse.includes(el.sponsor_id)) {
      if (!sponsorsInUse.includes(el.sponsor_id)) {
        sponsorsInUse.push(el.sponsor_id);
        matchesInUse.push(el.match_id);
        return el;
      }
      sponsorsInUse.push(el.sponsor_id);
      matchesInUse.push(el.match_id);
    });
    return result;
  };

  useEffect(() => {
    console.log("matchData>>>>>", matchData);
    if (matchData) {
      if (matchData.maybeThePlayerOne) {
        setPlayer1(matchData.maybeThePlayerOne);
      }
      if (matchData.maybeThePlayerTwo) {
        setPlayer2(matchData.maybeThePlayerTwo);
      }

      if (matchData.maybeThePlayerOne && matchData.maybeThePlayerTwo) {
        setPlayers([matchData.maybeThePlayerOne, matchData.maybeThePlayerTwo]);
      }
    }
  }, [matchData]);

  const matchInfo = useMemo(
    () =>
      !matchData
        ? {}
        : {
            id: matchData.match.match_api_id,
            date: matchData.match.updatedAt,
            name: matchData.match.name,
            dataId: matchData.match.id,
          },
    [matchData]
  );

  const countriesData = [
    {
      label: "USA",
      value: "USA",
    },
  ];

  const stancesData = [
    {
      label: "Left",
      value: "Left",
    },
    {
      label: "Right",
      value: "Right",
    },
  ];

  const oddsMakerData = oddsMakerList.map((val, index) => ({ ...val, key: index }));
  const [player1, setPlayer1] = useState(players[0]);
  const [player2, setPlayer2] = useState(players[1]);

  const onChangePlayerData = (e) => {
    if (selectedPlayer === 0) {
      setPlayer1({ ...player1, [e.target.name]: e.target.value });
    } else if (selectedPlayer === 1) {
      setPlayer2({ ...player2, [e.target.name]: e.target.value });
    }
  };

  const getPlayerData = () => {
    if (selectedPlayer === 0) {
      return player1;
    } else if (selectedPlayer === 1) {
      return player2;
    }
  };

  const getSponsorName = (id) => {
    const sponsor = sponsorList.find((s) => s.id === id);
    return sponsor.name;
  };

  const getSponsorImage = (id) => {
    const sponsor = sponsorList.find((s) => s.id === id);
    return sponsor.logo;
  };

  const onLiveVideo = (id) => {
    makeRequest("post", "/sponsors/update-live", { id });
  };

  const deleteCountry = (matchId, sponsorId, countryId) => {
    console.log("DELETE DATA: ", {
      matchId,
      sponsorId,
      countryId,
    });
    return makeRequest("post", `matches/update-sponsor/delete-country`, {
      matchId,
      sponsorId,
      countryId,
    });
  };

  const updateSponsor = async () => {
    console.log("COUNTRIES LIST: ", matchData.sponsors);
    console.log({ selectedSponsor });
    console.log({ selectedCountry });
    mutate();
    let isAvailable = true;
    matchData.sponsors.forEach((el) => {
      if (el.country) {
        isAvailable = false;
      }
    });
    setShowSponsorModal(false);
    if (isAvailable && selectedCountry) {
      await makeRequest("post", "/matches/update-sponsor", {
        countryCode: selectedCountry?.id,
        matchId,
        sponsorId: selectedSponsor,
      });
    } else if (selectedCountry) {
      await makeRequest("post", "/matches/connect-sponsor", {
        countryCode: selectedCountry?.id,
        matchId,
        sponsorId: selectedSponsor,
      });
    }
    await makeRequest("post", "/matches/connect-sponsor", {
      countryCode: null,
      matchId,
      sponsorId: selectedSponsor,
    });
    setSelectedSponsor(null);
    setSelectedCountry(null);
  };

  // if (!match) {
  //   return <Redirect to="/" />;
  // }

  const renderPlayer = (player) => {
    const renderInfo = Object.keys(player).filter(
      (item) =>
        ![
          "id",
          "photo",
          "name",
          "nationality",
          "createdAt",
          "updatedAt",
          "PlayerHistories",
          "history",
          "match_id",
          "picture",
        ].includes(item)
    );

    // console.log("ABOUT >>> ", renderInfo);

    const arrayCount = (renderInfo.length - (renderInfo.length % 3)) / 3 + 1;
    const mockPhoto =
      "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6c/Black_photo.jpg/450px-Black_photo.jpg";
    return (
      <div className="onyx-flex-layout">
        <div className="col-1-3 flex justify-center ">
          <img
            src={player.PhotoUrl || player.PICTURE || player.picture || mockPhoto}
            alt="player"
            className="onyx-player-demo-portrait"
          />
        </div>
        <div
          className="col-2-3"
          style={{
            maxHeight: 500,
            overflow: "scroll",
          }}
        >
          <div className="flex justify-between">
            {withLabel(<Detail>{player.name}</Detail>, "NAME", true)}
            {withLabel(
              <div className="flex items-center">
                <img src="/images/usa.png" alt="flag" className="onyx-flag mr-1" />
                <Detail>{player.nationality}</Detail>
              </div>,
              "NATIONNALITY",
              true,
              true
            )}
          </div>
          {Array(arrayCount)
            .fill(null)
            .map((el, index) => {
              const countlist = index + 1;
              return (
                <div className="flex justify-between mb-1">
                  {renderInfo
                    .filter((item) => player[item])
                    .filter((item) => item !== "entity_type")
                    .filter((item) => item !== "global_id")
                    .filter((item) => item !== "Updated")
                    .filter((item) => item !== "Created")
                    .filter((item) => item !== "PhotoUrl")
                    .filter((item, i) => i < countlist * 3 && i >= countlist * 3 - 3)
                    .map((item) =>
                      item !== "color" ? (
                        <div className="col-1-5">
                          {withLabel(
                            <Detail>{player[item]}</Detail>,
                            item
                              .replace(/([A-Z])/g, " $1")
                              .trim()
                              .toUpperCase(),
                            true
                          )}
                        </div>
                      ) : (
                        <div className="col-1-5">
                          {withLabel(
                            <ColorPicker value={player.color} isPreview />,
                            "COLOR",
                            true
                          )}
                        </div>
                      )
                    )}
                </div>
              );
            })}
          {/* <div>
            {withLabel(
              <table className="onyx-match-table">
                <thead>
                  <tr>
                    <th>DATE</th>
                    <th>OPPONENT</th>
                    <th>W-L-D</th>
                    <th>LAST 3</th>
                    <th>RESULT</th>
                  </tr>
                </thead>
                <tbody>
                  {player?.history?.map((match) => (
                    <tr key={player.name + match.id}>
                      <td>{moment(match.date).format("YYYY-MM-DD")}</td>
                      <td>{match.opponent}</td>
                      <td>{`${match.win}-${match.loss}-${match.draw}`}</td>
                      <td>
                        {match?.last3?.map((mark, index) => (
                          <span
                            key={player.name + match.id + index}
                            className={`onyx-round-check ${mark > 0 ? "green" : "red"}`}
                          >
                            {"X "}
                          </span>
                        ))}
                      </td>
                      <td>{match.result}</td>
                    </tr>
                  ))}
                </tbody>
              </table>,
              "Match History"
            )}
          </div> */}
        </div>
      </div>
    );
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };

  const matchEditStack = (
    <div className="onyx-breadcrumb">
      <Link to="/matches" className="text-gray">
        Matches
      </Link>
      <span className="text-white">{" > "}</span>
      <span className="text-gray">Edit Match</span>
    </div>
  );

  // const country = placesAvailable.find((el) => el.id === matchData?.sponsors[0].country);

  console.log(`Udpated`);

  console.log({ selectedSponsor });

  const matchedSponsor =
    matchData &&
    matchData.sponsors.find(
      (sponsor) => sponsor.country && sponsor.Sponsor.id === selectedSponsor
    );

  return (
    <Layout menuSelected={1}>
      {showSponsorModal && (
        <Modal
          title="Edit Sponsor Area"
          width={300}
          onClose={() => {
            setShowSponsorModal(false);
            setSelectedSponsor(null);
            setSelectedCountry(null);
          }}
        >
          <div className="sponsor-area-modal-row">
            <PlaceAutoComplete
              selectHandler={(countryId) => {
                setSelectedCountry(countryId);
              }}
            />
          </div>
          {/* <div className="sponsor-area-modal-row">
            <div className="col-2-3">
              {match.sponsorList[0].area.map((place) => (
                <div
                  className="onyx-flex-layout sponsor-area-lines"
                  key={`place${place.id}`}
                >
                  <div className="col-2-5">{place.center}</div>
                  <div className="col-2-5">{place.radius} miles</div>
                  <div className="col-1-5 flex justify-end items-center">
                    <CloseOutlined className="text-white" />
                  </div>
                </div>
              ))}
            </div>
          </div> */}
          <div className="sponsor-area-modal-row">
            <div className="col-2-3">
              {matchedSponsor && (
                <div className="onyx-flex-layout sponsor-area-lines">
                  {/* HERE */}
                  <div>{matchedSponsor.country}</div>
                  <div
                    className="col-1-5 flex justify-end items-center"
                    onClick={async () => {
                      await deleteCountry(
                        matchedSponsor.match_id,
                        matchedSponsor.sponsor_id,
                        matchedSponsor.country
                      );
                      setSelectedCountry(null);
                      mutate();
                      // setSelectedSponsor(null);
                      // setShowSponsorModal(false);
                      // setTimeout(() => {
                      //   setShowSponsorModal(true);
                      // }, 10);
                    }}
                  >
                    <CloseOutlined className="text-white" />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="sponsor-area-modal-row mt-2">
            <img
              src="/images/map_demo.png"
              alt="map_demo"
              className="sponsor-area-map-demo"
            />
          </div>
          <div className="sponsor-area-modal-footer">
            <Button
              className="col-left"
              onClick={() => {
                setShowSponsorModal(false);
                setSelectedSponsor(null);
                setSelectedCountry(null);
              }}
            >
              Cancel
            </Button>
            <Button className="col-right" primary onClick={updateSponsor}>
              Save
            </Button>
          </div>
        </Modal>
      )}
      {showPlayerModal && (
        <Modal
          title="Fighter Information"
          width={800}
          onClose={() => {
            setShowPlayerModal(false);
            setSelectedPlayer(-1);
          }}
        >
          <div className="player-modal-row">
            <div className="col-1-4">
              {withLabel(<Dropzone className="py-4" />, "Fighter")}
            </div>
            <div className="col-1-4">
              {withLabel(
                <InputText
                  value={getPlayerData().name}
                  name="name"
                  onChange={onChangePlayerData}
                  placeholder="Fighter Name"
                />,
                "Name"
              )}
              {withLabel(
                <InputText
                  value={getPlayerData().height}
                  name="height"
                  onChange={onChangePlayerData}
                  placeholder="180"
                />,
                "Height"
              )}
              {withLabel(
                <InputText
                  value={getPlayerData().neck}
                  name="neck"
                  onChange={onChangePlayerData}
                  placeholder="52"
                />,
                "Neck"
              )}
              {withLabel(
                <InputText
                  value={getPlayerData().forearm}
                  name="forearm"
                  onChange={onChangePlayerData}
                  placeholder="52"
                />,
                "Forearm"
              )}
            </div>
            <div className="col-1-4">
              {withLabel(
                <ColorPicker
                  value={getPlayerData().color}
                  name="color"
                  type="default"
                  onChange={(val) => {
                    onChangePlayerData({ target: { name: "color", value: val } });
                  }}
                  placeholder="#FFFFFF"
                />,
                "Player Color"
              )}
              {withLabel(
                <InputText
                  value={getPlayerData().reach}
                  name="reach"
                  onChange={onChangePlayerData}
                  placeholder="52"
                />,
                "Reach"
              )}
              {withLabel(
                <InputText
                  value={getPlayerData().chest}
                  name="chest"
                  onChange={onChangePlayerData}
                  placeholder="52"
                />,
                "Chest"
              )}
              {withLabel(
                <InputText
                  value={getPlayerData().calf}
                  name="calf"
                  onChange={onChangePlayerData}
                  placeholder="52"
                />,
                "Calf"
              )}
            </div>
            <div className="col-1-4">
              {withLabel(
                <Select
                  placeholder="Sponsor"
                  value={getPlayerData().nationality}
                  onChange={(e) => console.log(e)}
                  options={countriesData}
                />,
                "Nationality"
              )}
              {withLabel(
                <InputText
                  value={getPlayerData().wrist}
                  name="wrist"
                  onChange={onChangePlayerData}
                  placeholder="0"
                />,
                "Wrist"
              )}
              {withLabel(
                <InputText
                  value={getPlayerData().bicep}
                  name="bicep"
                  onChange={onChangePlayerData}
                  placeholder="0"
                />,
                "Bicep"
              )}
              {withLabel(
                <Select
                  placeholder="Stance"
                  value={getPlayerData().stance}
                  onChange={(e) => console.log(e)}
                  options={stancesData}
                />,
                "Stance"
              )}
            </div>
          </div>
          <div className="player-modal-footer">
            <Button
              className="mr-2"
              onClick={() => {
                setShowPlayerModal(false);
                setSelectedPlayer(-1);
              }}
            >
              Cancel
            </Button>
            <Button
              primary
              onClick={() => {
                setShowPlayerModal(false);
                setSelectedPlayer(-1);
              }}
            >
              SAVE EDITS
            </Button>
          </div>
        </Modal>
      )}
      <AppBar title={matchInfo.name} stackNavigator={matchEditStack}>
        <div className="onyx-flex-layout">
          <Button
            className="mr-2"
            onClick={() => {
              onLiveVideo(matchInfo.dataId);
            }}
          >
            Live Video
          </Button>
          <Button className="mr-2" onClick={() => {}}>
            Preview Video
          </Button>
          <Button primary onClick={() => {}}>
            Save Edits
          </Button>
        </div>
      </AppBar>
      <div className="onyx-flex-layout">
        <div className="col-1-3 pb-2">
          <Card className="h-full">
            <div className="onyx-flex-layout">
              <div className="col-left">
                {withLabel(<Detail>{matchInfo.name}</Detail>, "MATCH NAME")}
                {withLabel(
                  <Detail>{moment(matchInfo.date).format("MMMM Do, YYYY")}</Detail>,
                  "DATE"
                )}
              </div>
              <div className="col-right">
                {withLabel(<Detail>{matchInfo.id}</Detail>, "MATCH ID")}
              </div>
            </div>
          </Card>
        </div>
        <div className="col-2-3">
          <Card
            title="Home"
            right={
              <div className="onyx-flex-layout">
                <div
                  className="onyx-action mr-3"
                  onClick={() => console.log("Importing data...")}
                >
                  Import Data
                </div>
                <EditButton
                  onClick={() => {
                    setSelectedPlayer(0);
                    setShowPlayerModal(true);
                  }}
                >
                  Edit Fighter
                </EditButton>
              </div>
            }
          >
            {renderPlayer(player1)}
          </Card>
        </div>
      </div>
      <div className="onyx-flex-layout">
        <div className="col-1-3 pb-2">
          <Card title="Sponsor Associated" className="h-full">
            <div className="flex flex-col onyx-associated-sponsor-list-container">
              {matchData &&
                matchData.sponsors[0] &&
                groupMatchData().map((sponsor) => {
                  console.log("sponsor>>>>", sponsor);
                  return (
                    <div key={`sponsorList${sponsor.id}`} className="sponsors-container">
                      <div className="sponsor-wrapper">
                        {/* <img src={getSponsorImage(sponsor.sponsorId)} alt="sponsor" /> */}
                        <div className="sponsor-info-wrapper">
                          <span className="sponsor-name-title ">
                            {sponsor.Sponsor.name}
                          </span>
                          <img
                            src={
                              getEnvEndpoints().baseApiUrl + "/" + sponsor.Sponsor.avatar
                            }
                            alt="sponsor"
                            className="sponsor-image"
                          />
                        </div>

                        <EditButton
                          onClick={() => {
                            // TODO: Select sponsor id to edit here
                            setShowSponsorModal(true);
                            setSelectedSponsor(sponsor.Sponsor.id);
                          }}
                        >
                          Edit Sponsor IP
                        </EditButton>
                      </div>
                    </div>
                  );
                })}
            </div>
          </Card>
        </div>
        <div className="col-2-3">
          <Card
            title="Away"
            right={
              <div className="onyx-flex-layout">
                <div
                  className="onyx-action mr-3"
                  onClick={() => console.log("Importing data...")}
                >
                  Import Data
                </div>
                <EditButton
                  onClick={() => {
                    setSelectedPlayer(1);
                    setShowPlayerModal(true);
                  }}
                >
                  Edit Fighter
                </EditButton>
              </div>
            }
          >
            {renderPlayer(player2)}
          </Card>
        </div>
      </div>
      <Table
        dataSource={oddsMakerData}
        pagination={false}
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
      >
        <Column title="ODDS MAKER" dataIndex="name" key="name" />
        <Column title="MATCH ID" key="matchId" render={() => match.matchId} />
        <Column
          title="ODDS"
          dataIndex="odds"
          key="odds"
          render={({ home, away }) => {
            let left = home[home.length - 1];
            let right = away[away.length - 1];
            const isRisedLeft =
              home.length === 1 ? left >= 0 : left > home[home.length - 2];
            const isRisedRight =
              away.length === 1 ? right >= 0 : right > away[away.length - 2];
            if (left >= 0) {
              left = "+" + left;
            }
            if (right >= 0) {
              right = "+" + left;
            }
            return (
              <div className="onyx-odds-layout">
                <div className={"mr-2"}>
                  {isRisedLeft ? (
                    <UpOutlined className="onyx-odds-up" />
                  ) : (
                    <DownOutlined className="onyx-odds-down" />
                  )}
                </div>
                <div className="onyx-odds-live">
                  {left}/{right}
                </div>
                <div className={"ml-2"}>
                  {isRisedRight ? (
                    <UpOutlined className="onyx-odds-up" />
                  ) : (
                    <DownOutlined className="onyx-odds-down" />
                  )}
                </div>
              </div>
            );
          }}
        />
      </Table>
    </Layout>
  );
};

export default MatchesEdit;
